<template>
<!-- 导航开始 -->
   <el-row class='nav' type="flex" align='middle'>
        <el-col :span="6"><img src="../assets/pic/logo.png" alt="logo" class="nav_logo"></el-col>
        <el-col :span='11' class="nav_tab">
            <ul>
                <li v-for="item in tabList" :key="item.id"><router-link :to=item.page >{{item.name}}</router-link></li>
            </ul>
        </el-col>
        <el-col :span='7' class="nav_right">
            <span @click="user_login"><img src="../assets/pic/login.png" :alt="$t('user.login') ">
                <router-link to="/login" class="login" v-if="!$store.state.token">{{ $t('user.login') }}</router-link>  
                <router-link to="/dashboard" class="dashboard" v-else>{{ $t('user.dashboard') }}</router-link>
            </span>
            <span v-if="!$store.state.token">
                <img src="../assets/pic/phone.png" alt="注册">
                <router-link to="/register" class="reg">{{ $t('user.register') }}</router-link>
            </span>
            <!-- 下拉菜单开始 -->
            <el-dropdown class="dropdown_menu">
                <el-button type="primary" round size='mini'>
                    {{$t('language.chinese')}}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="changeLanguage(0)">{{$t('language.chinese')}}</el-dropdown-item>
                    <el-dropdown-item @click.native="changeLanguage(1)">{{$t('language.english')}}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- 下拉菜单结束 -->
        </el-col>
   </el-row>
<!-- 导航结束 -->
</template>

<script>
import axios from "axios";

export default {
    name:'navbar',
    data() {
        return {

        }
    },
    computed:{
        tabList(){
            return [
            {
                id:1,
                page:'/',
                name:this.$t('nav.indexPage')
            },{
                id:2,
                page:'/fuwu',
                name:this.$t('nav.serviceList')
            },{
                id:3,
                page:'/xuzhi',
                name:this.$t('nav.customerNotes')
            },
            // {
            //     id:4,
            //     page:'/connect',
            //     name:this.$t('nav.contactUs')
            // },
        ]
        }
    },
    methods:{
      user_login() {
        axios.post('/api/test', {userName: '', userId: ''}).then(result => {
          console.log('result:', result);
        })
      },
      changeLanguage(val){
        if(val){
            // 设置英文模式
            this.$i18n.locale='en'
            localStorage.setItem('languageSet',this.$i18n.locale)
        }else{
            // 设置中文模式
            this.$i18n.locale='zh'
            localStorage.setItem('languageSet',this.$i18n.locale)
        }
      }
    }
}
</script>

<style scoped>
    .nav {
        height: 80px;
        text-align: center;
        border-bottom: 1px solid lightgray;
        box-shadow: 0 0 1px;    
    }
    .nav_right span{
        margin-right: 5px;
    }
    .nav_right span img {
        vertical-align: middle;
    }
    .nav_tab ul {
        display: flex;
        justify-content: space-between;
    }
    ul li{
        width: 25%;
    }
    ul a {
        display: block;
        background-color: #fff;
        height: 80px;
        line-height: 80px;
    } 
    .router-link-exact-active {
        color:#fff !important;
        background-color: #3b79ff;
    }
    .login {
        padding-left: 5px;
        padding-right: 10px;
        font-size: 14px;
    }
    .reg {
        padding:0 5px;
        color:#979797;
        font-size: 14px;
    }
    .dropdown_menu {
        margin:0 50px
    }
</style>