<template>
  <div id="app">

    <!-- 登录前 -->
    <div class="no-login" v-if="!chooseNav">
      <Navbar></Navbar>
      <router-view></router-view>
    </div>

    <!-- 登录后-->
    <div class="login-successful" v-if="chooseNav">
      <Header></Header>
        <el-row class='main-content' type="flex" align='middle' justify="center">
          <el-col :span="20">
            <router-view></router-view>
          </el-col>
        </el-row>
      <Footer></Footer>
    </div>

  </div>
</template>

<script>
import Navbar from './components/navbar.vue'
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  name: 'App',
  components: {
    Navbar,
    Header,
    Footer
  },
  data(){
    return {
      chooseNav: false
    }
  },
  watch: {
    $route(to, from){
      let routes = ['/','/fuwu','/xuzhi','/connect','/login','/register']
      if(routes.includes(to.path)){
        this.chooseNav = false
      }else{
        this.chooseNav = true
      }
    },
  },

  mounted(){
  }
}
</script>

<style scoped>


</style>
