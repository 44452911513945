// 应用路由文件
import VueRouter from 'vue-router'
import store from '../vuex';

// 引入组件
// import home from '../components/home.vue'
// import fuwu from '../components/fuwu.vue'
// import xuzhi from '../components/xuzhi.vue'
// import connect from '../components/connect.vue'

const router =  new VueRouter({
    mode:'history',
    routes: [
        {
            path: '/',
            component: () => import('@/components/home'),
            meta: { auth: false },
        }, 
        {
            path: '/fuwu',
            component: () => import('@/components/fuwu'),
            meta: { auth: false },
        }, 
        {
            path: '/xuzhi',
            component: () => import('@/components/xuzhi'),
            meta: { auth: false },
        }, 
        {
            path: '/connect',
            component: () => import('@/components/connect'),
            meta: { auth: false },
        }, 
        {
            path: '/login',
            component: () => import('@/components/login'),
            meta: { auth: false },
        }, 
        {
            path: '/register',
            component: () => import('@/components/register'),
            meta: { auth: false },
        }, 

        {
            path: '/forgetPassword',
            component: () => import('@/components/forgetPassword'),
            meta: { auth: false },
        }, 
        {
            // 用户中心
            path: '/dashboard',
            component: () => import('@/pages/dashboard'),
            meta: { auth: false },
            // children: [
            //     {
            //       path: '/dashboard/coderequest',
            //       component: () => import('@/pages/codeRequest')
            //     }
            // ]
        },
        {
            // 提交订单
            path: '/coderequest',
            component: () => import('@/pages/codeRequest'),
            meta: { auth: false },
        },
        {
            // 历史订单
            path: '/codes',
            component: () => import('@/pages/codes'),
            meta: { auth: false },
        },
        {
            // 点数历史
            path: '/credits',
            component: () => import('@/pages/credits'),
            meta: { auth: false },
        },
        {
            // 服务列表
            path: '/packs',
            component: () => import('@/pages/packs'),
            meta: { auth: false },
        },
        {
            // 我的资料
            path: '/profile',
            component: () => import('@/pages/profile'),
            meta: { auth: false },
        },
        {
            // 新建工单
            path: '/ticketcreate',
            component: () => import('@/pages/ticketCreate'),
            meta: { auth: false },
        },
        {
            // 我的工单
            path: '/ticketslist',
            component: () => import('@/pages/ticketsList'),
            meta: { auth: false },
        },
        {
            // 工单详细
            path: '/ticketDtl',
            component: () => import('@/pages/ticketDtl'),
            meta: { auth: false },
        },
        {
            // api设置
            path: '/userapi',
            component: () => import('@/pages/userApi'),
            meta: { auth: false },
        },
        {
            // 支付宝
            path: '/pay_init',
            component: () => import('@/pages/payments/zfb'),
            meta: { auth: false },
        },
        {
            // 微信
            path: '/wechat',
            component: () => import('@/pages/payments/wechat'),
            meta: { auth: false },
        },
        {
            // paypal
            path: '/buycredits',
            component: () => import('@/pages/payments/buyCredits'),
            meta: { auth: false },
        },
        {
            // 客户为付款
            path: '/invoices',
            component: () => import('@/pages/invoices'),
            meta: { auth: false },
        },
        {
            // // 登录历史
            path: '/iplogrpt',
            component: () => import('@/pages/iplogrpt'),
            meta: { auth: false },
        },
        {
            // // 登录历史
            path: '/changepassword',
            component: () => import('@/pages/changePassword'),
            meta: { auth: false },
        },
        {
            path:"/payGateWay",
            component: () => import('@/pages/payments/payGateWay.vue'),
            meta: {auth: false}
        },
        {
            path:"/wechatAuth",
            component: () => import('@/pages/loginRedirect.vue'),
            meta: {auth: false}
        },
        {
            path:"/transaction",
            component: () => import('@/pages/transactionRecordPage.vue'),
            meta: {auth: false}
        },
        {
            path:"/quickLogin",
            component: () => import('@/pages/quickLogin.vue'),
            meta: {auth: false}
        }
        
    ]   
})

router.beforeEach((to, from, next) => {
    //to.meta.auth 表示需要做登录鉴权
    const token = store.state.token
    if (!to.meta.auth) {
      //store.state.token 表示已经登录 可以直接next
      //没有登录 跳转到/login 并携带参数redirect 方便登录后直接跳转到to.path
      if(!token ){
        if((to.path=='/login' || to.path=='/register' || to.path=='/' || to.path=='/fuwu'
         || to.path=='/xuzhi' || to.path=='/connect' || to.path=='/forgetPassword' || to.path == '/quickLogin')){
            next()
        }else{
            next('/login')
        }
      }else {
        next()
      }

        // if (token && (to.path !== '/login' || to.path !== '/register') ) {
        // // 有token 但不是去 login页面 通过
        //     next()
        // } else if (token && (to.path !== '/login' || to.path !== '/register')) {
        // // 有token 但是去 login页面 不通过 重定向到首页
        //     next('/')
        // } else if (!token && to.path !== '/login') {
        // // 没有token 但不是去 login页面 不通过（未登录不给进入）
        //     next() 
        // } else {
        // // 剩下最后一种 没有token 但是去 login页面 通过
        //     next()
        // }
    }
    else{
        next()
    }
  })
  export default router;