<template>
    <div class="footer">
      <el-row class='footer-top' type="flex" align='middle' justify="center">
        <el-col :span="18" class="footer-link">
            <ul class="footer-nav-list">
              <li><router-link to="/">HOME</router-link></li>
              <li><router-link to="/dashboard">{{ $t('dashboard.clientCenter') }}</router-link></li>
              <li><router-link to="/packs">{{ $t('packs.title') }}</router-link></li>
              <li><router-link to="/codes">{{ $t('codes.title') }}</router-link></li>
            </ul>
            <div class="pay-gateway">
              <img src="../assets/pic/pay-gateway.png" alt="">
              <a href="https://jq.qq.com/?_wv=1027&amp;k=5wpOmqg" target="_blank" alt="售后群">
                <img src="../assets/pic/QQGroup.png" alt="">
              </a>
            </div>
        </el-col>
      </el-row>
      <el-row class='footer-bottom' type="flex" align='middle' justify="center">
        <el-col :span="18" class="footer-site-info">
          <p>西安安捷达信息科技</p>
          <p>Agenda Network</p>
          <a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备19007937号-1</a>
        </el-col>
      </el-row>
    </div>

</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {
    }
  },
  components:{
  },
  methods:{

  },
  mounted() {

  },
}
</script>

<style scoped>

.footer-top{
  background-color: #545c64;
}
.footer-link{
  display: flex;
  flex-direction: column;
}
.footer-nav-list{
  border-bottom: 1px solid #ffff;
  padding: 50px 0 10px;
}
.footer-nav-list a{
  padding: 10px;
  color: #FFF;
}
.footer-nav-list a:hover{
  color: #409EFF;
}
.pay-gateway{
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.pay-gateway a{
  padding-left: 25px;
}

.footer-bottom{
  background: #545c64;
  color: #FFFFFF;
  padding-bottom: 0.5rem;
}

.footer-bottom a {
  color: #FFFFFF;
  display: inline;
  padding-left: 0.2rem;
}

.footer-bottom a:hover {
  color: #409EFF;
}

.footer-bottom p {
  display: inline;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.footer-site-info a:hover{
  color: #409EFF;
}
</style>