import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
		//登录状态
		loginStatus:JSON.parse(localStorage.getItem('userInfo')) ? true: false,
		//token
		token: JSON.parse(localStorage.getItem('userInfo')) ?  JSON.parse(localStorage.getItem('userInfo')).token : '',
		//用户信息(昵称/头像)
		userInfo:JSON.parse(localStorage.getItem('userInfo')) || {}
	},
	getters:{},
	mutations:{
		//一旦进入了home,就需要执行这个方法,把用户信息读出来
		initUser(state){
			let userInfo = localStorage.getItem('userInfo');
			if( userInfo ){
				userInfo = JSON.parse( userInfo );
				state.userInfo = userInfo;
				state.loginStatus = true;
				state.token = userInfo.token;
			}
		},
		//登录后保存用户信息
		login(state,userInfo){
			state.userInfo = userInfo;
			state.loginStatus = true;
			state.token = userInfo.token;
			localStorage.setItem('userInfo',JSON.stringify(userInfo));
		},
		// 退出登录
		loginOut(state){
			state.loginStatus = false;
			state.userInfo = {};
			state.token = null;
			//删除本地存储的信息
			localStorage.removeItem('userInfo');
		}
	},
	actions:{}
})
