import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import SIdentify  from './components/common/identify.vue'

// 引入elementUI组件库
import ElementUI from 'element-ui';
// 引入elementUI全部样式
import 'element-ui/lib/theme-chalk/index.css';

// 引入vue-router
import VueRouter from 'vue-router';
import router from './router/index'

//引入store
import store from './vuex/index'

// axios
import axios from 'axios';

// 引入全局样式
import './assets/css/common.css'

//中英文切换
Vue.use(VueI18n)
const i18n=new VueI18n({
    locale:localStorage.getItem('languageSet')||'zh',
    messages:{
        'zh':require('./locales/zh'),
        'en':require('./locales/en')
    }
})
// 图形验证码
Vue.use(SIdentify)

Vue.prototype.axios = axios

// 关闭生产提示
Vue.config.productionTip = false

axios.defaults.baseURL = 'http://localhost:8125/';

// 应用elementUI
Vue.use(ElementUI);

// 应用vue-router
Vue.use(VueRouter)

//使用mock模拟接口
// 开发环境下引入mock.js
// if(process.env.NODE_ENV==='development'){
//     require('../mock')
// }

new Vue({
    render: h => h(App),
    router: router,
    store,
    i18n,

}).$mount('#app')