<template>
  <div class="header" >
    <div :class="{hidden:!isShow}"></div>
    <el-row class='header-top' type="flex" align='middle' v-show="isShow">
        <el-col :span="8" :push="4">
            <img src="../assets/logo.png" alt="logo">
        </el-col>
        <el-col :span="8" :pull="4" class="right-left">
            <!-- <el-button size='mini' type="info">
                <span><i class="el-icon-user-solid"></i>您的点数为：</span>
                <span class="orange">RMB -27.130</span>
            </el-button> -->
            <el-button size='mini' type="primary">
                {{ $t('header.qqGroup') }}
            </el-button>
            <el-button size='mini' type="primary" @click="loginOut">
                <i class="el-icon-user-solid"></i>
                {{ $t('header.logout') }}
            </el-button>
            <el-dropdown class="dropdown_menu">
                <el-button size='mini'>
                    <span class="lang">
                        {{$t('language.chinese')}}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="changeLanguage(0)"><span class="lang"><img src="../assets/pic/zh.png" alt="zh">{{$t('language.chinese')}}</span></el-dropdown-item>
                    <el-dropdown-item @click.native="changeLanguage(1)"><span class="lang"><img src="../assets/pic/english.png" alt="english">{{$t('language.english')}}</span></el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </el-col>
    </el-row>
    <el-row class='nav' type="flex" align='middle' :class="{'top-layout':!isShow}">
        <el-col :span="21" :push="3">
            <el-menu
                :default-active="$route.path"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
                background-color="#545c64"
                text-color="#fff"
                active-text-color="#ffa500"
                router>
                <el-menu-item index="/dashboard"><i class="el-icon-s-custom"></i>{{ $t('dashboard.clientCenter') }}</el-menu-item>
                <el-menu-item index="/coderequest"><i class="el-icon-shopping-cart-1"></i>{{ $t('codeRequest.title') }}</el-menu-item>
                <el-menu-item index="/codes"><i class="el-icon-s-order"></i>{{ $t('codes.title') }}</el-menu-item>
                <el-submenu index="/buy">
                    <template slot="title"> <i class="el-icon-shopping-bag-1"></i>{{ $t('header.topUp') }}</template>
                    <el-menu-item index="/pay_init"><a href="#"><img src="../assets/pic/zfb.png" alt="zfb">{{ $t('invoices.aliPay') }}</a></el-menu-item>
                    <el-menu-item index="/wechat"><img src="../assets/pic/wechat.png" alt="weChat">{{ $t('invoices.weChat') }}</el-menu-item>
                    <el-menu-item index="/buycredits"><img src="../assets/pic/paypal.png" alt="paypal">Paypal</el-menu-item>
                    <!-- <el-menu-item index="/buycredits"><img src="../assets/pic/paypal.png" alt="paypal">Paypal</el-menu-item> -->
                </el-submenu>
                <el-menu-item index="/packs"><i class="el-icon-present"></i>{{ $t('packs.title') }}</el-menu-item>
                <el-menu-item index="/ticketslist"><i class="req">?</i>{{ $t('ticketList.title') }}</el-menu-item>
                <el-menu-item index="/profile"><i class="el-icon-user-solid"></i>{{ $t('profile.title') }}</el-menu-item>
                <el-menu-item index="/credits"><i class="el-icon-time"></i>{{ $t('credits.title') }}</el-menu-item>
                <el-menu-item index="/transaction">{{ $t('transaction.title') }}</el-menu-item>
                <!-- <el-menu-item index="/userapi"><i class="el-icon-s-tools"></i>API设置</el-menu-item> -->
            </el-menu>
        </el-col>
    </el-row>
 </div>
</template>

<script>
export default {
  name: 'Header',
  data() { 
    return {
        activeIndex: '1',
        activeIndex2: '1',
        isActive:false,
        selectedText: '购买点数',
        isShow:true,
        personalBalance: {},
    }
  },
  components:{
  },
  computed:{

  },
  methods:{
    changeLanguage(val){
        if(val){
            // 设置英文模式
            this.$i18n.locale='en'
            localStorage.setItem('languageSet',this.$i18n.locale)
        }else{
            // 设置中文模式
            this.$i18n.locale='zh'
            localStorage.setItem('languageSet',this.$i18n.locale)
        }
    },
    handleSelect(key, keyPath) {
        this.activeIndex = key
        console.log(key, keyPath,this.activeIndex,this.activeIndex2);
    },
    handleScroll() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // let headerTop = this.$refs.headerTop.offsetHeight;
        if (scrollTop > 75) {
            // console.log('开始固定')
            this.isShow = false
            
        }else{
            // console.log('开始不固定')
            this.isShow = true
        }
    },
    loginOut(){
        this.$store.commit('loginOut')
        this.$router.push('/')
    },
    handleGetUserBalance(){
      getPersonalBalance().then((data)=>{
        this.personaBalance = data;
      })
    },
  },
  mounted() {
    window.addEventListener("scroll",this.handleScroll)
    // this.handleGetUserBalance();
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  }
 }
</script>

<style scoped>
.header-top{
    justify-content: space-between;
}
.dropdown_menu{
    justify-content: space-between;
    margin-left: 5px;
}
.el-dropdown-menu__item .lang img{
    vertical-align: middle;
    padding-right: 3px;
}
.right-left{
    display: flex;
    justify-content: flex-end;
}
.el-menu.el-menu--horizontal{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 0;
}
.req{
    font-size: 18px;
    font-weight: 600;
    padding-right: 10px;
}
.active{
    color: #ffa500;
}
.nav{
    background-color: #545c64;
}
.router-link-exact-active {
    color:orange !important;
}
.el-submenu__title i{
    color: inherit;
}
.top-layout{
    position: fixed;
    top: 0;
    width: 100%;
    margin-bottom: 75px;
    z-index: 1999;
}
.hidden{
    height: 135px;
}
</style>